import {
  Flex,
  Grid,
  Text,
  useColorModeValue,
  Portal,
  Box,
  Input,
  FormLabel,
  useToast,
  Button,
  Image,
  Stack,
} from "@chakra-ui/react";
import { HSeparator } from "components/Separator/Separator";
import CountDown from "components/CountDown/CountDown";
import React, { useEffect, useState, useRef } from "react";
import { ReactSVG } from "react-svg";
import "./project.css";
import { selectedShowAtom, showsScheduleBookingDetailAtom } from "state/show";
import { useRecoilValue } from "recoil";
import { useBookingActions } from "hooks/useBookingActions";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { showTextFloor } from "variables/columnsData";
import { NumericFormat } from "react-number-format";

function BookingInformation() {
  const [selectedSeat, setSelectedSeat] = useState([]);
  const [lockByAdmin, setLockByAdmin] = useState("");
  const [seatWaiting, setSeatWaiting] = useState("");
  const [seatBooked, setSeatBooked] = useState("");
  const [desktopMap, setDesktopMap] = useState("");
  const [infoSelectedSeat, setInfoSelectedSeat] = useState({});
  const [uniqueString, setUniqueString] = useState([]);
  const selectedShow = useRecoilValue(selectedShowAtom);
  const listAssignSeatingPlan = useRecoilValue(showsScheduleBookingDetailAtom);
  const bookingActions = useBookingActions();
  const bookingRef = useRef();
  const [adult, setAdult] = useState(0);
  const [child, setChild] = useState(0);
  const [legendMap, setLegendMap] = useState();
  const [ticketPrices, setTicketPrices] = useState([]);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await bookingActions.getListBookingDetails(
          { show_schedule_detail_id:  selectedShow?.booking?.show_schedule_detail_id },
          0,
          ""
        );
        const { data } = await bookingActions.getSeatingPlan(
          selectedShow?.booking?.seatingplan_id
        );
        if (data) {
          setDesktopMap(`${data.data.desktop_maps}?type=svg`);
          setLegendMap(`${data.data.legend_desktop_maps}`);
        }
        const {
          data: { data: bookingData },
        } = await bookingActions.getBookingInfo();
        if (bookingData) {
          const seatsChoose =
            bookingData.seats?.map(
              (i) => `lune_seat_${i.seat_number}_${i.floor}`
            ) || [];
          setSelectedSeat(seatsChoose);
          setInfoSelectedSeat(bookingData);
          setAdult(bookingData.adult);
          setChild(bookingData.child);
        }
        const {
          data: { data: seatingLockData },
        } = await bookingActions.getSeatingLock();
        if (seatingLockData) {
          const locks = seatingLockData.map(
            (i) => `lune_seat_${i.seat_number}_${i.floor}`
          );
          setLockByAdmin(locks);
        }
        const {
          data: { data: seatingWaitingData },
        } = await bookingActions.getSeatingWaitingPayment();
        if (seatingWaitingData) {
          const seatingWaiting = seatingWaitingData.map(
            (i) => `lune_seat_${i.seat_number}_${i.floor}`
          );
          setSeatWaiting(seatingWaiting);
        }
        const {
          data: { data: seatingBookedData },
        } = await bookingActions.getSeatingBooked();
        if (seatingBookedData) {
          const seatingBooked = seatingBookedData.map((i) => ({
            seat: `lune_seat_${i.seat_number}_${i.floor}`,
            status: i.status,
          }));
          setSeatBooked(seatingBooked);
        }

        const {
          data: { data: ticketPrices },
        } = await bookingActions.getTicketPrices({
          show_id: selectedShow.booking.show.id,
          venue_id: selectedShow.booking.venue.id,
          seatingplan_id: selectedShow.booking.seatingplan_id,
        });
        setTicketPrices(ticketPrices);
      } catch (error) {
        console.warn(error);
      }
    };
    fetchData();
  }, []);
  const toast = useToast();
  useEffect(() => {
    if (Object.keys(infoSelectedSeat).length !== 0 && infoSelectedSeat?.seats) {
      infoSelectedSeat.seats.forEach((i) => {
        const text = showTextFloor(i.floor);
        setUniqueString((prevState) => [...prevState, text]);
      });
    }
    if (infoSelectedSeat.seats?.length === 0) {
      setUniqueString([]);
    }
  }, [infoSelectedSeat]);
  useEffect(() => {
    if (
      selectedSeat.length === parseInt(adult || 0) + parseInt(child || 0) &&
      selectedSeat.length !== 0
    ) {
      bookingRef.current.scrollIntoView({ behavior: "smooth" });
      setShowSubmitButton(true);
    } else {
      setShowSubmitButton(false);
    }
  }, [selectedSeat, adult, child]);
  function onSeatClick(event) {
    const seatNumber = event.target.getAttribute("id");
    const seat = listAssignSeatingPlan.find(
      (i) => `lune_seat_${i.seat}_${i.floor}` === seatNumber
    );
    if (seat) {
      window.open(`#/admin/booking/create/detail/${seat?.booking_id}`, '_blank')
      return 
    }
    const [first, second, seat_number, floor] = seatNumber.split("_");
    const totalSeat = parseInt(adult || 0) + parseInt(child || 0);
    if (totalSeat === 0) {
      bookingRef.current.scrollIntoView({ behavior: "smooth" });
      toast({
        description: "Please add number seat",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    const seatNumberBooked = seatBooked && seatBooked.map((i) => i.seat);
    if (seatNumberBooked.includes(seatNumber)) {
      toast({
        description: "Not Allow",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const filterSelectedSeat = selectedSeat.filter((i) => i !== seatNumber);
    if (filterSelectedSeat.length + 1 > totalSeat) {
      toast({
        description: "That's enough",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    setSelectedSeat((preState) => [...preState, seatNumber]);
    if (selectedSeat.includes(seatNumber)) {
      setSelectedSeat((preState) => preState.filter((i) => i !== seatNumber));
    }
    if (selectedSeat.includes(seatNumber)) {
      bookingActions
        .removeSeatBooking({
          seat_number,
          floor: parseInt(floor),
        })
        .then((res) => {
          if (res.status === 200) {
            bookingActions.getBookingInfo().then((res) => {
              if (res.status === 200) {
                setInfoSelectedSeat(res.data.data);
              }
            });
          }
        })
        .catch((err) => {
          toast({
            description: `${err.response.data.meta.message}`,
            status: "error",
            duration: 5000,
            position: "top-right",
          });
        });
    } else {
      bookingActions
        .keepSeatBooking({
          seat_number,
          floor: parseInt(floor),
        })
        .then((res) => {
          if (res.status === 200) {
            bookingActions.getBookingInfo().then((res) => {
              if (res.status === 200) {
                setInfoSelectedSeat(res.data.data);
              }
            });
          }
        })
        .catch((err) => {
          setSelectedSeat((preState) =>
            preState.filter((i) => i !== seatNumber)
          );
          toast({
            description: `${err.response.data.meta.message}`,
            status: "error",
            duration: 5000,
            position: "top-right",
          });
        });
    }
  }
  const bgProfile = useColorModeValue("white");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const uniqueStrings = [...new Set(uniqueString)];
  const concatenatedStrings = uniqueStrings.join(",");
  const colorBackGround = (zoneID) => {
    switch (zoneID) {
      case 1:
        return "#FCB515";
      case 2:
        return "#59BC67";
      case 3:
        return "#5E2F8A";
      default:
        break;
    }
  };

  return (
    <>
      <Flex mt={{ sm: "100px", md: "100px" }}>
        <Flex
          w="100%"
          direction="column"
          mb="24px"
          justifyContent={{ sm: "center", md: "space-between" }}
          backdropFilter="blur(21px)"
          boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
          border="1.5px solid"
          borderColor={borderProfileColor}
          bg={bgProfile}
          p="24px"
          borderRadius="20px"
        >
          <Text fontSize="20px" fontWeight="bold" ref={bookingRef}>
            Booking Information
          </Text>
          {selectedShow.booking && (
            <>
              <Grid templateColumns="repeat(2, 1fr)" my={2}>
                <Flex align="center">
                  Show Name:{" "}
                  <Text
                    fontWeight="bold"
                    color={"green.400"}
                    fontSize="18px"
                    ml={1}
                  >
                    {selectedShow.booking.show.name}
                  </Text>
                </Flex>
                <Flex align="center">
                  Venue:
                  <Text
                    fontWeight="bold"
                    color={"green.400"}
                    fontSize="18px"
                    ml={1}
                  >
                    {selectedShow.booking.venue.name}
                  </Text>
                </Flex>
              </Grid>
              <HSeparator />
              <Grid templateColumns="repeat(2, 1fr)" my={2}>
                <Flex align="center">
                  Show Date:
                  <Text
                    fontWeight="bold"
                    color={"green.400"}
                    fontSize="18px"
                    ml={1}
                  >
                    {moment(selectedShow.booking.show_date).format(
                      "DD/MM/YYYY"
                    )}
                  </Text>
                </Flex>
                <Flex align="center">
                  Show Time:
                  <Text
                    fontWeight="bold"
                    color={"green.400"}
                    fontSize="18px"
                    ml={1}
                  >
                    {selectedShow.booking.show_time.slice(0, -3)}
                  </Text>
                </Flex>
              </Grid>
              <HSeparator />
            </>
          )}
          <Flex gap={4} my={3}>
            <Flex align="center">
              <FormLabel mb={0} mr={1}>
                Adult
              </FormLabel>
              <NumericFormat
                customInput={Input}
                thousandSeparator=","
                fontSize="sm"
                ms="4px"
                size="sm"
                maxW="60px"
                defaultValue={0}
                value={adult}
                onKeyDown={(e) => {
                  if (e.keyCode === 190) {
                    e.preventDefault();
                  }
                }}
                onChange={async (val) => {
                  const adultValue = val.target.value;
                  if (adultValue) {
                    setAdult(adultValue);
                  } else {
                    setAdult("");
                  }
                  await bookingActions.updateBookingInfo({
                    adult: parseInt(adultValue),
                    child: parseInt(child),
                  });
                  const {
                    data: { data: bookingData },
                  } = await bookingActions.getBookingInfo();
                  const seatsChoose =
                    bookingData.seats?.map(
                      (i) => `lune_seat_${i.seat_number}_${i.floor}`
                    ) || [];
                  setSelectedSeat(seatsChoose);
                }}
              />
            </Flex>
            <Flex align="center">
              <FormLabel mb={0} mr={1}>
                Child (5-12 ages)
              </FormLabel>
              <NumericFormat
                customInput={Input}
                thousandSeparator=","
                fontSize="sm"
                ms="4px"
                size="sm"
                maxW="60px"
                defaultValue={0}
                value={child}
                onKeyDown={(e) => {
                  if (e.keyCode === 190) {
                    e.preventDefault();
                  }
                }}
                onChange={async (val) => {
                  const childValue = val.target.value;
                  if (childValue) {
                    setChild(childValue);
                  } else {
                    setChild("");
                  }
                  await bookingActions.updateBookingInfo({
                    adult: parseInt(adult),
                    child: parseInt(val.target.value),
                  });
                  const {
                    data: { data: bookingData },
                  } = await bookingActions.getBookingInfo();
                  const seatsChoose =
                    bookingData.seats?.map(
                      (i) => `lune_seat_${i.seat_number}_${i.floor}`
                    ) || [];
                  setSelectedSeat(seatsChoose);
                }}
              />
            </Flex>
          </Flex>
          <HSeparator />

          <>
            <Grid templateColumns="repeat(2, 1fr)" my={2}>
              <Flex align="center">
                Zone:{" "}
                <Text fontWeight="bold" color={"green.400"} ml={1}>
                  {infoSelectedSeat?.seats &&
                    infoSelectedSeat.seats[0]?.zone?.name}
                </Text>
              </Flex>
              <Flex align="center">
                Floor:{" "}
                <Text fontWeight="bold" color={"green.400"} ml={1}>
                  <span>{concatenatedStrings}</span>
                </Text>
              </Flex>
            </Grid>
            <HSeparator />
            <Grid templateColumns="repeat(2, 1fr)" my={2}>
              <Flex align="center">
                Seat NO:
                <Text
                  cursor={"pointer"}
                  fontWeight="bold"
                  color={"green.400"}
                  ml={1}
                >
                  {infoSelectedSeat?.seats
                    ?.map((seat) => seat.seat_number)
                    .join(", ")}
                </Text>
              </Flex>
            </Grid>
          </>
          <Flex align="center" justify="center" gap={4}>
            <Button
              colorScheme="blue"
              mr={3}
              disabled={!showSubmitButton}
              onClick={() => {
                history.push("/admin/booking/create/payment");
              }}
            >
              Submit
            </Button>
            <Button
              onClick={async () => {
                await bookingActions.cancelBooking();
                history.push("/admin/booking/create");
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex position="relative">
        <Stack
          position="absolute"
          p={"5px 10px"}
          // backgroundColor={"white"}
          left="0"
          direction="column"
        >
          <Text fontWeight={"bold"} color="#939496" textAlign="center" fontSize={{base: "16px", sm : "10px"}}>
            LEGEND IN ZONES
          </Text>
          <Grid
            templateColumns="repeat(3, auto)"
            gap="6px"
            fontSize="10px"
            color="white"
            textAlign="center"
          >
            <Text color="#939496">ZONE</Text>
            <Text color="#939496">SEATS</Text>
            <Text color="#939496">OFFICIAL RATE (VND)</Text>
            {ticketPrices.map((i, index) => (
              <React.Fragment key={index}>
                <Text
                  textAlign="center"
                  backgroundColor={colorBackGround(i.zone_id)}
                  px={1}
                  fontSize={{base: "12px", sm : "8px"}}
                  fontWeight={"bold"}
                  py={2}
                >
                  {i.zone.name}
                </Text>
                <Text
                  textAlign="center"
                  backgroundColor={colorBackGround(i.zone_id)}
                  px={3}
                  fontSize={{base: "12px", sm : "8px"}}
                  fontWeight={"bold"}
                  py={2}
                >
                  {i?.total_seat}
                </Text>
                <Text
                  px={3}
                  fontSize={{base: "12px", sm : "8px"}}
                  fontWeight={"bold"}
                  py={2}
                  textAlign="center"
                  backgroundColor={colorBackGround(i.zone_id)}
                >
                  {i.official_price.toLocaleString("en-US")}
                </Text>
              </React.Fragment>
            ))}
          </Grid>
        </Stack>
        <Flex
          position="absolute"
          right="10px"
          backgroundColor="white"
          padding="10px"
          width={{"xs" : "fit-content"}}
        >
          {legendMap && <Image maxW={{base: "250px" ,sm : "100px"}} src={legendMap} />}
        </Flex>
      </Flex>
      <ReactSVG
        id="svgImage"
        src={desktopMap}
        afterInjection={(svg) => {
          const seats = svg.querySelectorAll("circle");
          seats.forEach((seat) => {
            seat.addEventListener("click", onSeatClick);
            const seatId = seat.getAttribute("id");
            if (selectedSeat.includes(seatId)) {
              seat.classList.add("selected");
            }
            if (lockByAdmin.includes(seatId)) {
              seat.classList.add("lockadmin");
            }
            seatBooked &&
              seatBooked.forEach((i) => {
                if (i.seat  === seatId) {
                  if (parseInt(i.status) === 0) {
                    seat.classList.add("booked_929497");
                  } else if (parseInt(i.status) === 1) {
                    seat.classList.add("booked_FBED21");
                  } else {
                    seat.classList.add("booked_FF8D8D");
                  }
                }
              });
            if (seatWaiting.includes(seatId)) {
              seat.classList.add("booked_929497");
            }
          });
        }}
      />
      <Portal>
        <Box>
          <CountDown expTime={selectedShow.booking.expire_at} />
        </Box>
      </Portal>
    </>
  );
}

export default BookingInformation;
